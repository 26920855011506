import './section.scss';
import { getClip } from '../../utilities/js/clip';

class Section {
    constructor(element, options) {
        const settings = {
            initAttr: 'data-section'
        };

        this.settings = Object.assign({}, settings, options);
        this.$section = element;

        this.initialize();
    }

    initialize() {
        this.observeSection(this.$section, this);
    }

    observeSection(section) {
        const sectionObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (!entry.target.dataset.noAnimation) {
                    if (entry.isIntersecting) {
                        entry.target.style.clipPath = getClip(
                            entry.target.dataset.overlapPosition,
                            entry.target.dataset.overlapWidth
                        );

                        [].slice
                            .call(entry.target.children)
                            .forEach((child) => {
                                childObserver.observe(child);
                            });
                    }
                } else {
                    if (entry.target.dataset.noclip === '1') return;

                    entry.target.style.clipPath = getClip(
                        entry.target.dataset.overlapPosition,
                        entry.target.dataset.overlapWidth
                    );
                }
            });
        });

        const options = {};

        const childObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.classList.contains('animation-slide-in')) {
                        entry.target.classList.add('animation-slide-in');
                    } else {
                        entry.target.classList.add('fade-in-transition');
                    }
                } else {
                    if (entry.target.classList.contains('animation-slide-in')) {
                        entry.target.classList.remove('animation-slide-in');
                    } else {
                        entry.target.classList.remove('fade-in-transition');
                    }
                }
            });
        }, options);

        sectionObserver.observe(section);

        setTimeout(() => {
            if (location.hash) location.href = location.hash;
        }, 200);
    }
}

export { Section };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $sections = $context.querySelectorAll(
            'section[data-section="root"]'
        );
        for (let i = 0; i < $sections.length; i++) {
            $sections[i].API = new Section($sections[i]);
        }
    }
});
